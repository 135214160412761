@font-face {
  font-family: "Fontsfree net pr16";
  src: url("../fonts/FontsFree-Net-pr16.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Fontsfree net proxima nova";
  src: url("../fonts/ProximaNova-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Fontsfree net proxima nova";
  src: url("../fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Fontsfree net proxima nova";
  src: url("../fonts/ProximaNova-Regular.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Regular.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Black.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Fontsfree net proxima nova";
  //  color: rgba(255, 255, 255, 0.53);
  background-color: #171d2d;
}

a {
  text-decoration: none;
}

select::-ms-expand {
  display: none;
}

.w-100 {
  width: 100% !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.6);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.6);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.6);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.6);
}

.paragraph-2 {
  margin-top: 15px;
  padding-right: 11px;
  padding-left: 11px;
  font-family: "Fontsfree net proxima nova";
  font-weight: 300;
  color: hsla(0, 0%, 100%, 0.53);
  line-height: 22px;
}

.text-span-4 {
  color: #fbb919;
}

.text-span-5 {
  color: #fbb919;
}

.text-block-2 {
  display: inline-block;
  margin-bottom: 25px;
  padding-left: 12px;
  font-family: "Fontsfree net proxima nova";
  font-size: 23px;
  font-weight: 300;
  line-height: 24px;
  color: #333;
}

.image {
  position: static;
  left: 0px;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 10px;
  /*  margin-left: -12px;*/
  padding-top: 16px;
  padding-bottom: 10px;
  float: none;
  clear: none;
  background-color: transparent;
  color: #fff;
  cursor: default;
}

.progress-column-1 {
  position: relative;
  float: left;
  width: 10%;
}

.progress-column-2 {
  width: 80%;
  float: left;
  position: relative;
}

.progress-column-3 {
  position: relative;
  width: 10%;
  float: right;
}

.uppload-bg:after {
  z-index: 1;
  cursor: pointer;
  content: "\D7";
  font-family: sans-serif;
  position: fixed;
  font-size: 32px;
  line-height: 0.5;
  right: 1.5vw;
  top: 1.5vw;
  color: rgba(0, 0, 0, 0.5);
}

.image.complete {
  display: none;
  width: 25px;
  /*  margin-right: -12px;*/
  padding-top: 14px;
  float: right;
}

.image.incomplete {
  display: block;
  width: 25px;
  /*  margin-right: -12px;*/
  padding-top: 14px;
  float: right;
}

.image.back-button {
  cursor: pointer;
}

.div-block-3 {
  position: relative;
  top: auto;
  bottom: auto;
  width: 20px;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: auto;
  padding-bottom: auto;
  float: left;
  clear: none;
}

.div-block-3.complete {
  position: static;
  bottom: 20px;
  overflow: visible;
  width: 20px;
  height: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  float: right;
  clear: none;
  text-decoration: none;
}

.div-block-4 {
  position: static;
  z-index: 100;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  float: none;
  clear: none;
}

.div-block-5 {
  position: relative;
  // z-index: 50;
  width: 100%;
  height: 12px;
  margin-top: 20px;
  margin-right: 0%;
  margin-left: 0%;
  float: none;
  border-radius: 20px;
  background-color: hsla(0, 0%, 93%, 0.4);
  opacity: 1;
}

.div-block-5.progress {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  // z-index: 60;
  width: 79%;
  margin-top: 0px;
  margin-left: 0px;
  float: none;
  clear: none;
  background-color: #fbb919;
  opacity: 1;
}

.div-block-6 {
  /* width: 100%; */
  /* max-width: 400px; */
  margin-top: 50px;
  height: 54px;
}

.text-span-4 {
  color: #fbb919;
}

.text-span-5 {
  color: #fbb919;
}

.select-field-5 {
  height: 70px;
  border-radius: 8px;
  font-family: "Fontsfree net proxima nova";
  font-size: 23px;
  font-weight: 300;
  border-color: #fbb919;
  opacity: 0.8;
}

.select-field-5:focus {
  opacity: 1;
}

.radio-button-field-6 {
  display: none;
}

.radio-button-field-7 {
  display: none;
}

.mt-0 {
  margin-top: 0 !important;
}

.modal .close::after {
  content: "\00d7";
}

.preview {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
  align-items: center;
  justify-content: center;
}

.preview .ReactCrop {
  // top: 50%;
  // left: 50%;
  // -ms-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  .ReactCrop__child-wrapper {
    height: 100%;
  }
}

.uppload-modal.visible,
.uppload-bg.visible {
  opacity: 1;
  display: block;
}

.uppload-modal > div {
  position: static;
}

.uppload-modal {
  position: fixed;
  color: #333;
  background: #fff;
  z-index: 11000;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0.25rem;
  box-shadow: 0 30px 150px rgb(0 0 0 / 20%);
}

.uppload-modal aside nav ul li svg {
  margin-right: 0.5rem;
  opacity: 0.5;
  transition: 0.3s;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.uppload-modal aside {
  background-color: #dfe6e9;
  transition: 1s;
}

.uppload-modal aside nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.uppload-modal aside nav ul li button {
  text-align: left;
  transition: 0.3s;
  font: inherit;
  background: none;
  appearance: none;
  border: none;
  padding: 1rem;
  border-radius: 0;
  color: inherit;
  color: #000;
}

@media (min-width: 860px) {
  .uppload-modal aside nav li button {
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
  }
}

@media (min-width: 860px) {
  .uppload-modal aside {
    width: 200px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    padding-bottom: 2rem;
  }
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.svg-inline--fa.fa-fw {
  width: 1.25em;
}

.uppload-modal aside nav ul li.active button {
  background-color: #fff;
}

.uppload-modal aside nav ul li.active button svg {
  opacity: 1;
}

.uppload-modal .currentPage {
  display: flex;
  flex: 1 0 0;
}

.uppload-modal .center-middle {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5rem;
  right: 5rem;
}

.uppload-modal .mb-full {
  margin-bottom: 1.5rem !important;
}

.uppload-modal .mb-full {
  margin-bottom: 1.5rem !important;
}

.uppload-modal #dragDropElement {
  border: 3px dashed #ddd;
  padding: 5rem 0;
  transition: 0.5s;
  font-size: 125%;
}

.primary-button {
  background-color: #fbb919 !important;
}

.uppload-modal .primary-button {
  background-color: #00a8ff;
  color: #fff;
  border: none;
  transition: 0.3s;
  font: inherit;
  font-size: 115%;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
}

.uppload-modal input {
  border: 2px solid #eee;
  width: 350px;
  max-width: 85vw;
  transition: 0.3s;
  font: inherit;
  font-size: 115%;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  display: block;
}

.uppload-modal ::-webkit-input-placeholder {
  color: rgb(117, 117, 117);
}

#microLinkInput {
  width: 100%;
  font-family: "Fontsfree net proxima nova";
}

.uppload-modal label div {
  margin-bottom: 1rem;
}

.uppload-modal label {
  font-size: 110%;
  margin-bottom: 1.5rem;
}

.uppload-modal #dragDropFileElt {
  display: none !important;
}

@media (min-width: 860px) {
  .uppload-modal section {
    position: absolute;
    right: 0;
    left: 200px;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    width: calc(100% - 200px);
  }
}

.uppload-modal section {
  display: flex;
}

.uppload-modal .crop-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .bottom-buttons {
    display: block;
    text-align: center;
    padding-bottom: 10px;
  }
}

.uppload-modal #dragDropElement {
  border: 3px dashed #ddd;
  padding: 5rem 0;
  transition: 0.5s;
  font-size: 125%;
}

.uppload-modal #dragDropElement.active {
  transform: scale(1.05);
  background-color: whitesmoke;
}

.react-datepicker__input-container > input {
  border-radius: 8px;
  background-color: transparent;
  opacity: 0.8;
  font-family: "Fontsfree net proxima nova";
  color: #fff;
  padding: 20.5px 12px;
  margin-bottom: 12px;
  font-size: 23px;
  line-height: 23px;
  font-weight: 300;
  cursor: text;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.center-all {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.uppload-modal .errorMessage {
  color: rgba(255, 255, 255, 0.9);
  background-color: #ff4757;
  padding: 1rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 1;
  transition: 0.5s;
}

.tag {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 10px;
  line-height: 6px;
  text-align: center;
  letter-spacing: 0.06em;
  padding: 7px;
  min-width: 58px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 8px;
  color: #ffffff;
  &.tag-pending,
  &.tag-yellow,
  &.tag-bank_verification {
    background: #fbac1f;
    color: #fff;
  }
  &.tag-approved,
  &.tag-completed,
  &.tag-social_security_confirmation {
    background: #4fb47c;
    line-height: 1;
  }
  &.tag-failed {
    background: #a21818;
  }
  &.tag-know_your_customer_form,
  &.tag-enroute {
    background: #460ad1;
  }
  &.tag-processing,
  &.tag-funding_form {
    background: #194bfb;
  }
  &.tag-canceled,
  &.tag-residency_verification {
    background: #9e9e9e;
  }
  &.tag-preapproved {
    background-color: #353f56;
  }
}

.footer-control-table {
  justify-content: space-between;
  button {
    background: transparent;
    &:disabled {
      opacity: 0.5;
    }
  }
  .gap-1 {
    font-weight: 300;
    color: #fff;
  }
}

.wrap-tabs {
  .react-tabs__tab-list {
    background: #353f56;
    border-bottom: 1px solid rgba(234, 236, 240, 0.5);
    .react-tabs__tab {
      padding: 12px 16px;
      color: #fff;
      font-weight: 300;
      &.react-tabs__tab--selected {
        background: transparent;
        border: none;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
          background: #dea722;
        }
      }
    }
  }
}

.divice {
  background: #9096a3;
  height: 1px;
  width: 100%;
  margin: 8px 0;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #fff;
  background-color: #dea722;
  border-color: #dea722;
}

.border-button {
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
  border: 1px solid #dea722;
  border-radius: 6px;
  background: transparent;
  font-weight: 500 !important;
  color: #fff;
  &.small {
    padding: 2px 12px;
    font-size: 12px;
  }
}

.close-modal-button {
  padding: 12px;
  background: #171d2d;
  color: #fff;
  font-weight: 300;
}

.modal-title {
  margin: 0;
  font-size: 17px;
  margin-bottom: 45px;
  line-height: 50px;
  font-weight: 200 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Proxima Nova";
  // padding: 0 40px;

  .Navbar__button {
    cursor: pointer;
    height: 48px;
    width: 48px;
  }
}

.modal-body label {
  font-weight: 300;
}

.error-message {
  color: #f44336;
}

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//   background-color:  #353F56;
// }

// ::-webkit-scrollbar {
//   width: 4px;
//   height: 3px;
//   background-color: #DEA722;
// }

// ::-webkit-scrollbar-thumb {
//   background-color: #DEA722;
// }

.text-center {
  text-align: center;
  justify-content: center;
}

.font-300 {
  font-weight: 300;
}

.Input {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  margin-bottom: 16px;
  overflow: hidden;
  padding-right: 8px;
  position: relative;
  transition-duration: 200ms;
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
  width: 100%;
  .Input__field-container {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    .Input__field {
      -moz-appearance: textfield;
      -webkit-appearance: textfield;
      border: 0;
      color: #444;
      display: block;
      font-size: 20px;
      height: 56px;
      letter-spacing: 0.1px;
      outline: none;
      padding-left: 16px;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      vertical-align: middle;
      width: 100%;
    }
  }
}

.Icon {
  display: inline-block;
  height: 24px;
  vertical-align: inherit;
  width: 24px;
}
.Input__icon {
  display: block;
  height: 56px;
  position: relative;
  width: 18px;
}
.Input__icon svg {
  fill: #ccc;
  height: 18px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 18px;
}
.Input__field::placeholder {
  color: #ccc !important;
  font-weight: 300;
}

.branchSelect {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  text-transform: lowercase;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  padding: 14px;
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;
  transition-duration: 200ms;
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
  border: 0;
  color: #444;
  display: block;
  font-size: 20px;
  height: 56px;
  letter-spacing: 0.1px;
  outline: none;
  padding-left: 16px;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.bank-account-button {
  -webkit-appearance: button;
  background-color: rgba(255, 255, 255, 0.12);
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%),
    inset 0 1px 0 0 rgb(255 255 255 / 10%);
  color: #fff;
  font-size: 20px;
  height: 56px;
  outline: 0;
  text-align: center;
  text-transform: none;
  transition-duration: 200ms;
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
  width: 100%;
  margin-bottom: 20px;
  font-weight: 300;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 45px;
  opacity: 0.95;
  img {
    width: 90px;
  }
  .bank-account-button:hover {
    opacity: 1;
  }
}

.main-page-wrap {
  *:not(i) {
    font-family: "Fontsfree net proxima nova";
  }
}

.ReactModalPortal {
  * {
    font-weight: 300;
  }
  & > div {
    z-index: 99999;
  }
}

.loading-cc {
  position: absolute;
  top: 0;
  color: #fff;
  background: #1e1e1e73;
  width: 100%;
  height: 100%;
  left: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}

@media only screen and (min-width: 1400px) {
  .custom-size-4 {
    flex: 0 0 auto;
    width: 52%;
  }

  .custom-size-3 {
    flex: 0 0 auto;
    width: 48%;
  }
}

.modal-title .Navbar__icon {
  height: 48px;
  padding: 12px;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition-duration: 100ms;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-in-out;
  width: 48px;
  color: #fff;
  display: flex;
}

.body-inheri {
  padding: 0 40px;
}

.body-inheri label,
.body-inheri span {
  font-family: Vaud, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 17px;
  font-weight: 400;
}

.body-inheri span {
  font-weight: 700;
}

.common-modal-button {
  -webkit-appearance: button;
  background-color: rgba(255, 255, 255, 0.12);
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%),
    inset 0 1px 0 0 rgb(255 255 255 / 10%);
  color: #fff;
  font-size: 20px;
  height: 56px;
  outline: 0;
  text-align: center;
  text-transform: none;
  transition-duration: 200ms;
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
  width: 100%;
  margin-bottom: 20px;
  font-weight: 300;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 45px;
  &:disabled {
    opacity: 0.5;
  }
  img {
    width: 90px;
  }
}

.primary-hover {
  -webkit-transition: background-color 350ms
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: background-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.primary-hover:hover {
  background-color: #f2b200;
  /* background-color: #c0840e; */
  color: white !important;
  -webkit-transition: background-color 350ms
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: background-color 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url("../imgs/squares.gif") center no-repeat #171c2d;
}

.se-pre-con-inplace {
  width: 100%;
  height: 100%;
  z-index: 900;
  background: url("../imgs/squares.gif") center no-repeat #222a42;
}

.se-pre-con-inplace-transparent {
  width: 100%;
  height: 100%;
  z-index: 900;
  background: url("../imgs/squares.gif") center no-repeat transparent;
}

.ReactModal__Content {
  min-width: 300px;
  min-height: 300px;
  transform: translate(-50%, -0%) !important;
  top: 100px !important;
}

.no-transform {
  transform: none !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.adp-tooltip--component {
  text-transform: none;
  max-width: 300px;
}

.table.border-table {
  width: 100%;
  margin-top: 15px;
  border-collapse: collapse;
  thead {
    th {
      border: 1px solid #ddd;
      padding: 10px;
      border-right: none;
      &:last-child {
        border-right: 1px solid;
      }
    }
  }
  tbody {
    td {
      border: 1px solid #ddd;
      padding: 10px;
      border-right: none;
      &:last-child {
        border-right: 1px solid;
      }
    }
  }
}

.text-nowrap {
  white-space: nowrap;
}

.short-input {
  width: 200px !important;
}

.text-regular {
  font-weight: 300;
  line-height: 40px;
}

.text-warning {
  color: #fcb91a;
}

.bank-country {
  height: 120px;
  margin-bottom: 20px;
  border-radius: 6px;
}

.flag {
  width: 33%;
}

.flag img {
  width: 50px;
  height: 50px;
}

.add-account-text {
  float: right;
  width: 66.6666%;
}

@media (max-width: 840px) {
  .uppload-modal {
    width: 90vw;
    height: 80vh;
    margin: 0 auto;
    top: 10vh;
    left: 5vw;
    .preview {
      min-height: 50vh;
    }
    .crop-container {
      .preview canvas {
        width: 100%;
        height: auto;
        max-height: 40vh;
      }
    }
  }

  .ReactModal__Content {
    top: 60px !important;
  }
}

@media (min-width: 860px) {
  .uppload-modal {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 720px;
    height: 500px;
  }
}

@media (min-width: 960px) {
  .uppload-modal {
    width: 800px;
  }
}

.reviews-container {
  .senja-embed {
    :is(.pt-10) {
      padding-top: 50px;
    }
    .swiper-slide.swiper-slide-active {
      padding-left: 10px;
    }
  }
}

@media (max-height: 660px) {
  .ReactModal__Content {
    top: 7% !important;
    max-height: 97% !important;
    .WrapModalButton {
      position: inherit;
      margin-bottom: 0;
    }
  }
}

@media (max-height: 565px) {
  .ReactModal__Content {
    top: 2% !important;
    max-height: 97% !important;
  }
}

.has-one-time-banner {
  // & > div {
  //   padding-top: 92.5px;
  // }
  // .header-webflow {
  //   top: 92.5px;
  // }
}
