.pdf-container {
  position: relative;
  width: 100%;
  height: 100%;
  .container {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  .menu-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 25px;
    background: #f1c40f;
    box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.25);
  }

  .title {
    flex-grow: 1;
    width: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-right: 50px;
  }

  .pagination {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    /* margin-right: 52px; */
  }

  .canvas-container {
    display: flex;
    justify-content: center;
    height: calc(100% - 60px);
    overflow: auto;
  }

  /* icon close */
  .gg-close {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 40px;
  }
  .gg-close::after,
  .gg-close::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 16px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
    border-radius: 5px;
    top: 8px;
    left: 1px;
  }
  .gg-close::after {
    transform: rotate(-45deg);
  }

  /* icon zoom in */
  .gg-zoom-in {
    position: fixed;
    top: 80px;
    right: 50px;
    box-sizing: border-box;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-radius: 100%;
    background: linear-gradient(to bottom, currentColor 5px, transparent 0)
      no-repeat 2px 5px/8px 2px;
    margin-top: -4px;
    color: #fff;
    cursor: pointer;
  }
  .gg-zoom-in::after,
  .gg-zoom-in::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 2px;
    height: 8px;
    background: currentColor;
  }
  .gg-zoom-in::after {
    transform: rotate(-45deg);
    border-radius: 3px;
    top: 10px;
    left: 12px;
  }
  .gg-zoom-in::before {
    top: 2px;
    left: 5px;
  }

  /* icon zoom out */
  .gg-zoom-out {
    position: fixed;
    top: 120px;
    right: 50px;
    color: #fff;
    box-sizing: border-box;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-radius: 100%;
    margin-top: -4px;
    cursor: pointer;
  }
  .gg-zoom-out::after,
  .gg-zoom-out::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    background: currentColor;
  }
  .gg-zoom-out::after {
    transform: rotate(-45deg);
    border-radius: 3px;
    width: 2px;
    height: 8px;
    top: 10px;
    left: 12px;
  }
  .gg-zoom-out::before {
    width: 8px;
    height: 2px;
    top: 5px;
    left: 2px;
  }
  /* icon next */
  .gg-play-track-next {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    min-width: 3px;
    height: 10px;
    background: currentColor;
  }
  .gg-play-track-next::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 0;
    height: 10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid;
    left: -7px;
  }

  /* icon prev */
  .gg-play-track-prev {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    min-width: 3px;
    height: 10px;
    background: currentColor;
  }
  .gg-play-track-prev::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 0;
    height: 10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 6px solid;
    right: -7px;
  }

  button {
    padding: 0 15px;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
}