
button {
  border: none
}

.auth0-lock-social-buttons-container {
  text-align: center;
  display: block;
  /*margin: 10px;*/
}

.auth0-lock-social-button {
  /*width: 50px;*/
  min-height: 42px;
  padding: 10px;
  /*margin:10px;*/
  width: calc(50% - 5px);
  margin-bottom: 10px;
  top: 0;
  left: 0;
  border-radius: 4px;
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
}
.auth0-lock-social-button[data-provider="google"] {
  background-color: #4285f4;
  margin-right:8px;
  background-size: 5%;
  background-position: center right 10px;
  min-height: 40px;
  top: 0;
  left: 0;
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
}

.auth0-lock-social-button.facebook-btn {
  background-color: #3b5998;
  background-repeat: no-repeat;
  color: rgba(255, 255, 255, 0.5);
  background-size: 5%;
  background-position: center right 10px;
  min-height: 40px;
  top: 0;
  left: 0;
  width: 50%;
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
}

.auth0-lock-social-button-icon {
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
}



.button-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 70px;
  margin-bottom: 12px;
  padding-left: 16px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 8px;
  background-color: rgba(255, 193, 7, 0);
  opacity: 0.8;
  font-family: 'Fontsfree net proxima nova';
  color: #fff;
  font-size: 23px;
  font-weight: 300;
}

.button-5:hover {
  opacity: 1;
}

.button-5:active {
  border-color: #fbb919;
}