.home-content {
  font-family: 'Fontsfree net proxima nova';
  font-weight: 200;
  h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Fontsfree net proxima nova';
    color: #fff;
    font-size: 48px;
    line-height: 44px;
    font-weight: 900;
    letter-spacing: 2px;
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 36px;
    line-height: 40px;
    font-weight: 900;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  a {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    font-weight: 200;
  }

  .sg-heading {
    height: 30px;
    margin-top: 50px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e3eaf1;
  }

  .header-webflow {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 10;
    height: 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: transparent;
  }

  .b1 {
    display: block;
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .b2 {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .b2.footer-link {
    padding: 10px;
    color: hsla(0, 0%, 100%, .3);
  }

  .b2.footer-link:hover {
    color: hsla(0, 0%, 100%, .5);
  }

  .c1 {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .c1.footer-nav-link {
    width: 160px;
    padding-top: 6px;
    padding-bottom: 6px;
  /*  color: #7d5208;*/
    color: white;
  }

  .color-family {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 40px;
  }

  .color {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #e4e4e4;
  }

  .color.cp-yellow-darker {
    background-color: #7d5208;
  }

  .color.cp-yellow-mid {
    background-color: #f2b200;
  }

  .color.cp-yellow-lighter {
    background-color: #fad996;
  }

  .color.cs-mint-darker {
    background-color: #009e5f;
  }

  .color.cs-mint-mid {
    background-color: #00d18d;
  }

  .color.cs-mint-lighter {
    background-color: #00ffbe;
  }

  .color.cb-darkest {
    background-color: #171d2d;
  }

  .color.cb-darker {
    background-color: #2f3748;
  }

  .color.cb-dark {
    background-color: #43436e;
  }

  .color.cb-mid {
    background-color: #909dad;
  }

  .color.cb-light {
    background-color: #8fa1b2;
  }

  .color.cb-lighter {
    background-color: #d2dde5;
  }

  .color.cb-lightest {
    background-color: #f4f8fa;
  }

  .color.cs-yellow-darker {
    background-color: #cea800;
  }

  .color.cs-yellow-mid {
    background-color: #ffd924;
  }

  .color.cs-yellow-lighter {
    background-color: #feff6d;
  }

  .color.cp-yellow-dark {
    background-color: #c0840e;
  }

  .color.cs-blue-mid {
    background-color: #1e94f3;
  }

  .color.cs-green-mid {
    background-color: #27c677;
  }

  .header-nav-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .primary-cta {
    /* height: 100%; */
    min-width: 120px;
    padding: 8px 20px 6px;
    border-radius: 6px;
    line-height: 20px;
    color:#f2b200;
    background-color: #171d2d;
    background: transparent;
    border: 2px #f2b200 solid;
    -webkit-transition: background-color 350ms cubic-bezier(.25, .46, .45, .94);
    transition: background-color 350ms cubic-bezier(.25, .46, .45, .94);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 1px;
    font-family: Fontsfree net proxima nova;
    text-transform: uppercase;
  }
  
  .primary-cta:hover {
      background-color: #f2b200;
  /*  background-color: #c0840e;*/
      color:white;
      -webkit-transition: background-color 350ms cubic-bezier(.25, .46, .45, .94);
    transition: background-color 350ms cubic-bezier(.25, .46, .45, .94);
  }

  .header-nav-link {
    padding-right: 12px;
    padding-left: 12px;
    color: #8fa1b2;
    font-size: 14px;
    font-weight: 200;
  }

  .header-nav-link:hover {
    color: #909dad;
  }

  .header-nav-link.w--current {
    color: #a5b1c9;
  }

  .hero {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    min-height: 700px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hero-copy-wrapper {
    display: block;
    width: 80%;
    float: left;
  }

  .hero-illo-bottom-container {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: right;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .tcb-mid {
    color: #909dad;
  }

  .tcb-mid.b1 {
    display: block;
  }

  .tcb-mid.text-weight-reg {
    font-weight: 200;
  }

  .text-weight-reg.text-align-center {
    text-align: center;
  }

  .tcb-darkest {
    color: #070036;
  }

  .tcb-darkest.clear-padding {
    display: inline-block;
  }

  .funds {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 1000px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hero-container {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .funds-container {
    position: relative;
    z-index: 2;
    display: block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .funds-illo-wrapper {
    position: relative;
    width: 100%;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
  }

  .text-center {
    text-align: center;
  }

  .funds-copy-wrapper {
    max-width: 580px;
    margin-right: auto;
    margin-left: auto;
  }

  .circle-bg {
    position: absolute;
    left: -150vw;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 0;
    width: 400vw;
    height: 400vw;
    border-radius: 50%;
    background-color: #fff;
  }

  .security {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding-top: 150px;
    padding-bottom: 150px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
  }

  .security-container {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .security-copy-wrapper {
    display: inline-block;
    width: 50%;
    padding-left: 60px;
  }

  .security-illo-wrapper {
    position: relative;
    width: 50%;
    border-radius: 50%;
  }

  .platform {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 150px;
    padding-bottom: 150px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
  }

  .platform-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .platform-copy-wrapper {
    width: 47%;
    padding-right: 40px;
    float: right;
  }

  .platform-illo-wrapper {
    position: relative;
    display: block;
    width: 55%;
    float: left;
  }

  .other-reasons {
    display: block;
    padding-top: 150px;
    padding-bottom: 150px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
  }

  .reasons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 60px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .reason-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    margin-bottom: 50px;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .reason-copy-wrapper {
    display: inline-block;
    padding-left: 20px;
  }

  .clear-top-padding {
    margin-top: 0px;
  }

  .apply-container {
    display: block;
    margin-top: 40px;
    padding-right: 5%;
    padding-left: 5%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .apply-copy-wrapper {
    max-width: 50%;
    float: left;
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
  }

  .apply-cta-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: right;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .tcb-light {
    color: #a5b1c9;
  }

  .faq {
    position: relative;
    display: block;
    overflow: hidden;
    height: 1200px;
    padding-top: 100px;
    padding-bottom: 100px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
  }

  .faq-container {
    position: relative;
    z-index: 2;
    overflow: visible;
    max-height: 1000px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 10px;
  }

  .faq-heading-wrapper {
    display: block;
  }

  .clear-padding {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .question-wrapper {
    padding-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e3eaf1;
    cursor: pointer;
  }

  .question-wrapper.bottom-question {
    border-bottom-color: transparent;
  }

  .tab-link {
    border-radius: 20px;
    background-color: transparent;
    color: #a5b1c9;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .tab-link:hover {
    color: #72799c;
  }

  .tab-link.w--current {
    background-color: #e3eaf1;
    color: #5a5eff;
  }

  .faq-tabs-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .faq-tab-content {
    padding-top: 30px;
    clear: right;
  }

  .tabs {
    left: 0px;
    top: -46px;
    right: 0px;
    display: block;
    height: 100%;
  }

  .faq-tab-text {
    padding-top: 2px;
  }

  .inline-link {
    color: #72799c;
    text-decoration: underline;
  }

  .inline-link:hover {
    color: #5a5eff;
  }

  .faq-circle-bg {
    position: absolute;
    left: -150vw;
    top: 400px;
    right: 0px;
    z-index: 0;
    width: 600vw;
    height: 600vw;
    border-radius: 50%;
    background-color: #f4f8fa;
  }

  .faq-circle-yellow {
    position: absolute;
    left: -100px;
    top: -100px;
    z-index: 0;
    width: 30vw;
    height: 30vw;
    border-radius: 50%;
    background-color: #ffd924;
  }

  .team {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .team-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-webflow {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: rgba(55, 65, 88, 0.95) none repeat scroll 0 0;
    background: rgba(55, 65, 88, 0.95);
  }
  
  .footer-index-webflow {
    position: relative;
    padding-top: 550px;
    padding-bottom: 100px;
    background-color: rgba(55, 65, 88, 0.95) none repeat scroll 0 0;
    background: rgba(55, 65, 88, 0.95);
  }

  .footer-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 60px;
    padding-left: 60px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .story-wrapper {
    display: inline-block;
    width: 40%;
  }

  .team-memebers-container {
    display: inline-block;
    width: 60%;
    padding-left: 10%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .team-member {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .team-member-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .team-member-photo-wrapper {
    display: inline-block;
    float: left;
  }

  .team-member-info-wrapper {
    display: inline-block;
    margin-left: 20px;
  }

  .faq-mint-ring {
    position: absolute;
    right: -40px;
    bottom: -40px;
    z-index: 2;
    overflow: hidden;
    width: 120px;
    height: 120px;
    border: 8px solid #00d18d;
    border-radius: 50%;
    background-color: transparent;
  }

  .footer-logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
    float: left;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .footer-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: right;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .footer-nav-line-div {
    width: 1px;
    height: 40px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: hsla(0, 0%, 100%, .1);
  }

  .footer-social-link {
    margin-left: 10px;
    opacity: 0.3;
  }

  .footer-social-link:hover {
    opacity: 0.5;
  }

  .footer-logo-light {
    margin-right: 10px;
  }

  .ff-logo-text {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1px;
  }

  .reason-icon {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }

  .platform-illo-top {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 2;
  }

  .funds-illo-bottom {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 0;
    display: inline-block;
  }

  .hero-illo {
    display: none;
    width: 100%;
    height: 100%;
  }

  .header-container {
    position: relative;
    z-index: 1;
    height: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .header-logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .hero-illo-top {
    position: relative;
    z-index: 2;
  }

  .hero-illo-bottom {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
  }

  .hero-illo-mid {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
  }

  .funds-illo-top {
    position: relative;
    z-index: 1;
    display: inline-block;
  }

  .apply-lightbox {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 1001;
    display: none;
    width: 100vw;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .apply-lightbox-container {
    position: relative;
    z-index: 1;
    width: 400px;
    height: auto;
    max-width: 400px;
    min-width: 320px;
    padding: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 6px 20px 0 rgba(114, 121, 156, .2);
  }

  .center-align-text {
    text-align: center;
  }

  .textfield {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    padding: 32px 20px 12px 60px;
    border: 1px solid transparent;
    background-color: transparent;
    color: #a5b1c9;
    font-size: 20px;
  
  }

  .textfield:hover {
    border-color: transparent;
  }

  .textfield:active {
    border-color: transparent;
  }

  .textfield:focus {
    border-color: transparent;
  }

  .textfield::-webkit-input-placeholder {
    color: #a5b1c9;
  }

  .textfield:-ms-input-placeholder {
    color: #a5b1c9;
  }

  .textfield::placeholder {
    color: #a5b1c9;
  }

  .textfield.clear-padding {
    margin-bottom: 0px;
    font-weight: 200;
  }

  .submit-cta-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .apply-form-heading-wrapper {
    margin-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
    clear: right;
  }

  .selector {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 50px;
    margin-bottom: 20px;
    padding: 4px 12px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #e3eaf1;
    border-radius: 30px;
    color: #a5b1c9;
    font-size: 16px;
  }

  .selector:hover {
    border-color: #a5b1c9;
  }

  .selector:active {
    border-color: #5a5eff;
  }

  .selector:focus {
    border-color: #5a5eff;
  }

  .selector::-webkit-input-placeholder {
    color: #a5b1c9;
  }

  .selector:-ms-input-placeholder {
    color: #a5b1c9;
  }

  .selector::placeholder {
    color: #a5b1c9;
  }

  .selector-options {
    height: 30px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-style: none;
    background-image: -webkit-linear-gradient(270deg, transparent, transparent);
    background-image: linear-gradient(180deg, transparent, transparent);
    color: #a5b1c9;
    font-size: 16px;
  }

  .apply-lightbox-background {
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(227, 234, 241, .7);
  }

  .navbar-2 {
    z-index: 1000;
  }

  .faq-questions-container {
    position: relative;
    z-index: 1;
    overflow: auto;
    width: 100%;
    height: 1000px;
    padding: 60px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 6px 20px 0 rgba(114, 121, 156, .2);
  }

  .security-illo-bottom {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
    width: auto;
    height: auto;
  }

  .security-illo-top {
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 1;
  }

  .hero-apply-cta-wrapper {
    margin-left: 20px;
  }

  .platform-illo-mid {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
  }

  .platform-illo-bottom {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
  }

  .header-bg {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #171d2d;
    box-shadow: none;
  }

  .answer-wrapper {
    display: none;
  }

  .logo-dark {
    padding-top: 6px;
    width:148px;
  }

  .heading-3 {
    font-size: 48px;
    line-height: 56px;
  }

  .body-2 {
    background-color: #171d2d;
  }

  .body-3 {
    background-color: #171d2d;
  }

  .heading-4 {
    color: #fff;
  }

  .hero-illo-top-wrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 1;
    display: block;
    height: 40vh;
    float: right;
  }

  .text-align-center {
    text-align: center;
  }

  .heading-6 {
    letter-spacing: 1.2px;
  }

  .heading-7 {
    letter-spacing: 1px;
  }

  .hero-illo-container {
    position: relative;
    z-index: 1;
    height: 100vh;
  }

  .calculator-container {
    margin-top: 60px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .currency-calculator-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .usd-input-container {
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
  }

  .currency-calculator-form-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .send-cta-wrapper {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
  }

  .shekel-input-wrapper {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
  }

  .why {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .why-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .why-heading-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .reasons-why-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    padding-right: 10%;
    padding-left: 10%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-flex: 0;
    /* -webkit-flex: 0 0 50%; */
    -ms-flex: 0 0 50%;
    flex: 1;
  }

  .reason-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 50%;
    padding: 40px 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 1 50%;
  }

  .reason-illo-wrapper {
    width: 80px;
    height: 80px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .image {
    display: block;
  }

  .support {
    padding-top: 100px;
    padding-bottom: 500px;
  }

  .how {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .how-heading-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .how-steps-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-flex: 0;
    /* -webkit-flex: 0 0 50%; */
    -ms-flex: 0 0 50%;
    flex: 1;
  }

  .how-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .step-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 25%;
    padding: 40px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 1 1 25%;
  }

  .step-copy-wrapper {
    display: inline-block;
    padding-top: 20px;
  }

  .step-number-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60px;
    height: 60px;
    padding-top: 2px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border: 2px solid #2f3748;
    border-radius: 30px;
  }

  .support-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 5%;
    padding-left: 5%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .support-copy-container {
    max-width: 50%;
    padding-right: 60px;
  }

  .support-illo-wrapper {
    max-width: 50%;
    padding-left: 50px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .text-weight-reg {
    font-weight: 400;
  }

  .text-weight-reg.tcb-light {
    color: #8fa1b2;
  }

  .support-number-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .support-number-icon-wrapper {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  .reviews {
    padding-top: 100px;
  }

  .reviews-container {
    position: absolute;
    left: 0px;
    top: -300px;
    right: 0px;
    border-radius: 10px;
    background-color: #fff;
  }

  .footer-logo-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 20%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .tcp-dark {
  /*  color: #c0840e;*/
    color: #a5b1c9;
  }

  .footer-nav-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 80px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    flex: 1;
  }

  .footer-nav-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .c2 {
    color: #fff;
    letter-spacing: 0.5px;
    font-size: 14px;
  }

  .c2a {
    color: #fff;
    letter-spacing: 0.5px;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;

  }

  .c2.tcb-mid {
    color: #909dad;
    line-height: 20px;
  }

  .c2.tcp-dark {
      color:white;
  /*  color: #c0840e;*/
  }

  .footer-disclaimer-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 60px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .inline-footer-link {
  /*  color: #7d5208;*/
    color: #a5b1c9;
    font-size: 14px;
    text-decoration: underline;
  }

  .slide-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding: 40px 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .review-blurb-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .slider {
    height: auto;
    background-color: transparent;
  }

  .review-person-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
  }

  .review-person-avatar-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 60px;
    height: 60px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 30px;
  }

  .review-person-info-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .news-container {
    border-top: 1px solid #d2dde5;
  }

  .news-logos-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .news-logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 14px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .textfield-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 240px;
    min-height: 70px;
    padding: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #909dad;
    border-radius: 6px;
    background-color: #171d2d;
    color: #a5b1c9;
    font-size: 16px;
  }

  .textfield-wrapper::-webkit-input-placeholder {
    color: #a5b1c9;
  }

  .textfield-wrapper:-ms-input-placeholder {
    color: #a5b1c9;
  }

  .textfield-wrapper::placeholder {
    color: #a5b1c9;
  }

  .textfield-wrapper.clear-padding {
    margin-bottom: 0px;
  }

  .textfield-icon-wrapper {
    position: absolute;
    top: 18px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-left: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .image-2 {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .transfer-options-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
      opacity: 0;
  }

  .transfer-options-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hero-illo-left-wrapper {
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 1;
  }

  .hero-illo-right-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 1;
  }

  .hero-illo-bottom-wrapper {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .hero-illo-bottom-base-wrapper {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .hero-illo-bird-wrapper {
    position: absolute;
    bottom: 100px;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 10%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .savings-blurb-wrapper {
    display: none;
  }

  .savings-tooltip-container {
    position: absolute;
    display: none;
  }

  .transfer-options-olehpay-wrapper {
    padding: 20px;
  }

  .yellow-label {
    margin-top: 10px;
    padding: 2px 12px 1px;
    border-radius: 15px;
    background-color: #fad996;
    color: #7d5208;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }

  .text-button-small {
    padding-right: 0px;
    padding-left: 0px;
    background-color: transparent;
    color: #909dad;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .text-button-small:hover {
    color: #8fa1b2;
  }

  .left-arrow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .slide-nav {
    display: none;
  }

  .right-arrow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .step-check-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60px;
    height: 60px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 30px;
    background-color: #fad996;
  }

  .send-cta {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 6px;
    background-color: #f2b200;
  }

  .send-icon-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 10px;
  }

  .label-dark {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 20px;
    margin-top: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    background-color: #2f3748;
    cursor: pointer;
  }

  .dark-label-text {
    color: #8fa1b2;
    font-size: 10px;
    font-weight: 500;
  }

  .fees-breakdown-lightbox {
    position: absolute;
    z-index: 11;
    display: none;
    width: 100vw;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(23, 29, 45, .6);
  }

  .fees-breakdown-container {
    width: 320px;
    min-width: 280px;
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 6px;
    background-color: #2f3748;
    box-shadow: 0 4px 40px 0 rgba(10, 15, 27, .6);
  }

  .c3 {
    color: #909dad;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }

  .textfield-label {
    position: absolute;
    left: 0px;
    top: 0px;
    margin-top: 12px;
    margin-left: 60px;
    color: #909dad;
    font-size: 10px;
  }

  .fees-breakdown-headeing-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 50px;
    padding-left: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(23, 29, 45, .5);
  }

  .close-cta-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    height: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .tabs-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    box-shadow: 0 1px 0 0 #000;
  }

  .tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: rgba(23, 29, 45, .5);
  }

  .tab.w--current {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(23, 29, 45, .5);
    box-shadow: inset 0 -2px 0 0 #f2b200;
  }

  .tab-amount {
    color: #8fa1b2;
    font-size: 14px;
    font-weight: 500;
  }

  .tab-label {
    color: #909dad;
    font-size: 10px;
  }

  .fee-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 5px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .fee-row-final {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    padding: 5px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .fee-breakdown-list-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .tab-pane-olehpay-2 {
    display: block;
  }

  .vs-div {
    width: 1px;
    height: 100px;
    background-color: #2f3748;
  }

  .vs-div-container {
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
  }

  .vs-copy-wrapper {
    position: absolute;
    left: 0px;
    top: 37px;
    right: 0px;
    width: 100%;
    height: 24px;
    background-color: #171d2d;
  }

  .vs-text {
    padding-top: 2px;
    color: #909dad;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  @media (max-width: 991px) {
    .fees-breakdown-lightbox {
      height: 100vh;
    }
    .header-webflow {
      height: 60px;
    }
    .header-nav-container {
      height: auto;
      padding-bottom: 20px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      background-color: #171d2d;
      display: none;
    }
    .header-nav-link {
      border-bottom: 1px solid #2f3748;
    }
    .hero {
      display: block;
      height: auto;
      min-height: auto;
      padding: 160px 40px 150px;
    }
    .hero-illo-bottom-container {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .tcb-mid.text-weight-reg.text-align-center.left-align-text-mobile {
      text-align: left;
    }
    .tcb-darkest.text-center-mobile {
      text-align: left;
    }
    .funds {
      height: 800px;
      padding-right: 40px;
      padding-left: 40px;
    }
    .hero-container {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .security {
      padding-right: 40px;
      padding-left: 40px;
    }
    .security-illo-wrapper {
      width: 50%;
      height: auto;
    }
    .platform {
      padding-right: 40px;
      padding-left: 40px;
    }
    .other-reasons {
      padding-right: 40px;
      padding-left: 40px;
    }
    .faq {
      padding-right: 20px;
      padding-left: 20px;
    }
    .faq-circle-yellow {
      width: 40vw;
      height: 40vw;
    }
    .team {
      padding-top: 100px;
      padding-right: 40px;
      padding-left: 40px;
    }
    .footer-webflow {
      padding-right: 40px;
      padding-left: 40px;
    }
    .footer-container {
      padding-right: 20px;
      padding-left: 20px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .faq-mint-ring {
      right: -30px;
      bottom: -30px;
      width: 80px;
      height: 80px;
    }
    .footer-logo-wrapper {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .footer-nav {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .menu-button {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px 12px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      border-radius: 6px;
      background-color: #2f3748;
    }
    .menu-button.w--open {
      background-color: #2f3748;
    }
    .header-logo-wrapper {
      padding-left: 0px;
    }
    .hero-apply-cta-wrapper {
      margin-top: 20px;
      margin-left: 0px;
    }
    .hero-illo-top-wrapper {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .text-align-center.left-align-text-mobile {
      text-align: left;
    }
    .currency-calculator-container {
      position: relative;
    }
    .currency-calculator-form-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .send-cta-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 20px;
      margin-left: 0px;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
    }
    .reasons-why-container {
      padding-right: 0%;
      padding-left: 0%;
    }
    .support-copy-container {
      padding-right: 20px;
    }
    .support-illo-wrapper {
      padding-left: 20px;
    }
    .footer-logo-container {
      max-width: auto;
      min-width: 40%;
    }
    .footer-nav-container {
      margin-top: 40px;
      margin-left: 0px;
    }
    .slide-wrapper {
      height: 100%;
    }
    .textfield-wrapper {
      max-width: auto;
      min-width: 250px;
      margin-top: 10px;
    }
    .transfer-options-container {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .savings-blurb-wrapper {
      display: block;
      padding-left: 20px;
      cursor: pointer;
    }
    .savings {
      color: #f2b200;
    }
    .view-savings-details-cta {
      color: #8fa1b2;
      font-weight: 500;
    }
    .savings-tooltip-container {
      position: absolute;
      left: 0px;
      bottom: 0px;
      z-index: 1;
      display: none;
      overflow: hidden;
      width: 290px;
      margin-bottom: 60px;
      border-radius: 6px;
      background-color: #2f3748;
      box-shadow: 0 4px 30px 0 rgba(0, 0, 0, .4);
    }
    .savings-tooltip-heading-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 50px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .close-tooltip-wrapper {
      position: absolute;
      top: 0px;
      right: 0px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 50px;
      height: 50px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .transfer-option-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 50px;
      margin-right: 15px;
      margin-left: 15px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-bottom: 1px solid #909dad;
    }
    .transfer-option-bottom-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 50px;
      margin-right: 15px;
      margin-left: 15px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-style: none;
      border-bottom-width: 1px;
      border-bottom-color: #909dad;
    }
    .transfer-option-olehpay-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 50px;
      padding-right: 15px;
      padding-left: 15px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-style: none;
      border-bottom-width: 1px;
      border-bottom-color: #909dad;
      background-color: #f2b200;
    }
    .image-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .label-dark {
      display: none;
    }
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 18px;
    }
    .b1 {
      font-size: 16px;
      line-height: 20px;
    }
    .b2 {
      font-size: 14px;
      line-height: 18px;
    }
    .c1 {
      font-size: 12px;
      line-height: 16px;
    }
    .primary-cta {
      padding-top: 9px;
      padding-bottom: 7px;
      font-size: 14px;
    }
    .hero {
      padding-top: 120px;
      padding-bottom: 100px;
    }
    .hero-copy-wrapper {
      width: 100%;
      margin-top: 0px;
      padding-right: 0px;
      clear: both;
    }
    .hero-illo-bottom-container {
      width: 100%;
    }
    .tcb-mid.text-center-mobile {
      text-align: center;
    }
    .tcb-darkest.text-center-mobile {
      text-align: center;
    }
    .funds {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .funds-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .funds-illo-wrapper {
      left: -25%;
      width: 150%;
    }
    .security {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .security-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .security-copy-wrapper {
      width: 100%;
      margin-top: 40px;
      padding-left: 0px;
    }
    .security-illo-wrapper {
      width: 70%;
    }
    .platform {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .platform-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .platform-copy-wrapper {
      width: 100%;
      margin-top: 40px;
      padding-right: 0px;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .platform-illo-wrapper {
      width: 80%;
    }
    .other-reasons {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .reason-wrapper {
      width: 100%;
      padding-right: 0px;
      padding-left: 0px;
    }
    .apply-copy-wrapper {
      width: 100%;
      max-width: 100%;
      float: none;
    }
    .apply-cta-wrapper {
      margin-top: 20px;
      float: none;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .faq {
      height: 1100px;
      padding: 50px 10px;
    }
    .faq-heading-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 20px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .tab-link {
      font-size: 14px;
    }
    .faq-tabs-menu {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .tabs {
      position: static;
    }
    .faq-circle-yellow {
      left: -50px;
      top: -50px;
    }
    .team {
      padding-top: 50px;
      padding-bottom: 100px;
    }
    .team-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .footer-webflow {
      padding-top: 40px;
      padding-bottom: 60px;
    }
    .footer-index-webflow {
      padding-top: 400px;
      padding-bottom: 60px;
    }
    .footer-container {
      margin-top: 60px;
      padding-right: 0px;
      padding-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
    }
    .story-wrapper {
      width: 100%;
    }
    .team-memebers-container {
      width: 100%;
      margin-top: 40px;
      padding-left: 0%;
    }
    .team-member-photo {
      width: 60px;
      height: 60px;
    }
    .team-member-photo-wrapper {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .footer-nav {
      margin-top: 40px;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .header-container {
      padding-right: 20px;
      padding-left: 20px;
    }
    .faq-questions-container {
      padding: 30px;
    }
    .heading {
      line-height: 32px;
    }
    .heading-2 {
      line-height: 24px;
    }
    .hero-illo-top-wrapper {
      width: 100%;
    }
    .calculator-container {
      margin-bottom: 120px;
    }
    .why {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .reasons-why-container {
      margin-top: 20px;
    }
    .reason-container {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .reason-illo-wrapper {
      width: 60px;
      height: 60px;
      margin-top: 8px;
    }
    .support {
      padding-top: 50px;
      padding-bottom: 400px;
    }
    .how {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .how-steps-container {
      max-width: 80%;
      margin-top: 20px;
    }
    .how-container {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .step-container {
      min-width: 50%;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .support-copy-container {
      padding-right: 30px;
    }
    .reviews-container {
      border-radius: 0px;
    }
    .footer-logo-container {
      max-width: auto;
      min-width: 50%;
    }
    .footer-nav-container {
      margin-top: 0px;
      margin-left: 0px;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
    }
    .footer-nav-wrapper {
      margin-top: 20px;
    }
    .c2 {
      font-size: 12px;
      line-height: 16px;
    }
    .c2a {
      font-size: 12px;
      line-height: 16px;
      text-decoration: underline;
      cursor: pointer;
      color:#fff;
    }
    .slide-wrapper {
      padding-right: 20px;
      padding-left: 20px;
    }
    .review-person-container {
      margin-top: 30px;
    }
    .mobile-call-to-action {
      display:block;
    }
  }

  @media (max-width: 479px) {
    .hero {
      padding-top: 80px;
      padding-right: 15px;
      padding-left: 15px;
    }
    .hero-copy-wrapper {
      margin-bottom: -40px;
    }
    .funds {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .funds-illo-wrapper {
      left: -50%;
      width: 200%;
    }
    .security {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .security-illo-wrapper {
      width: 80%;
    }
    .platform {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .platform-copy-wrapper {
      margin-top: 20px;
    }
    .platform-illo-wrapper {
      width: 100%;
    }
    .other-reasons {
      padding: 50px 20px;
    }
    .footer-container {
      margin-top: 60px;
    }
    .footer-nav {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .header-container {
      padding-right: 15px;
      padding-left: 15px;
    }
    .header-logo-wrapper.w--current {
      padding-left: 0px;
    }
    .calculator-container {
      margin-bottom: 80px;
    }
    .send-cta-wrapper {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .reason-container {
      max-width: auto;
      min-width: 100%;
    }
    .how {
      padding-right: 15px;
      padding-left: 15px;
    }
    .step-container {
      max-width: auto;
      min-width: 100%;
    }
    .support-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .support-copy-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      max-width: 100%;
      padding-right: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .support-illo-wrapper {
      width: 100%;
      height: 250px;
      max-width: 100%;
      padding-left: 0px;
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .review-person-container {
      margin-top: 20px;
    }
    .text-align-center-mobile {
      text-align: center;
    }
    .footer-index-webflow {
    padding-top: 600px;
  }
  }
  /* landing video section */
  
  .landing-video {
      margin-top: 100px;
  
  }
  
  @media only screen and (max-height: 630px) {
      .landing-page.features-landing>.landing-video>.video-browser {
          zoom: 1;
      }
  } 
  @media only screen and (max-width: 994px) {  
          .landing-video{
          display: none;
      }
  }

  /*
  @media only screen and (min-width: 768px) {
  .footer-index-webflow {
    padding-top: 150px !important;
  }
  }   
  */

  .video-browser {
      box-shadow: 0 20px 40px rgba(32,45,74,.1);
      }
      
      .landing-page.features-landing>.landing-video::after {
      content: 'Demo Checkout';
      display: block;
      margin-top: 40px;
      margin-bottom: -20px;
      font-size: 22px;
      font-weight: 300;
      text-align: center;
      color: #8D95A6;
  }
      
      .landing-video>.video-browser {
      margin: 0 auto;
      height: 517px;
      width: 867px;
      overflow: hidden;
      border: 1px solid rgba(32,45,74,.1);
      border-radius: 8px;
  }
      
      
      .landing-video>.video-browser>.controls {
      position: relative;
      z-index: 1;
      height: 80px;
      background: url("../imgs/olehpaybrowser.png");
      background-size: 870px 80px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
  }
      .landing-video>.video-browser>.video {
      transform: translate(-1px,-1px);
      margin-top: -25px;
  }

  .transfer-comparison {
      opacity:1;
      transition: 2s;
      visibility:visible;
      -webkit-transform:translateX(0) translateY(10PX) translateZ(0) scaleX(0.95) scaleY(0.95) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0);
      -moz-transform:translateX(0) translateY(10PX) translateZ(0) scaleX(0.95) scaleY(0.95) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0);
      -ms-transform:translateX(0) translateY(10PX) translateZ(0) scaleX(0.95) scaleY(0.95) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0);
      transform:translateX(0) translateY(10PX) translateZ(0) scaleX(0.95) scaleY(0.95) scaleZ(1) rotateX(0) rotateY(0) rotateZ(0) skewX(0) skewY(0);
  }

  .phone:hover {
    cursor: pointer;
  }

  .support-contact {
      padding: 200px 0px 200px 0px;
  }
  .div-block {
      margin: 25px 0px 25px 0px;
      width: 50%;
  }

  .next {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    margin: 63px 35px 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: #f2b200;
    font-family: 'Fontsfree net proxima nova';
    color: #fff;
    font-weight: 500;
  }

  .text-field-17 {
    display: block;
    height: 70px;
    margin-bottom: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 12px;
    float: none;
    border-radius: 8px;
    background-color: hsla(0, 0%, 100%, 0);
    opacity: 0.8;
    color: #fff;
    font-size: 23px;
    line-height: 22px;
    font-weight: 300;
    cursor: text;
  }

  .text-field-17:hover {
    border: 1px solid #fff;
    background-color: hsla(226.36363636363637, 32.35%, 13.33%, 1.00);
    opacity: 1;
  }

  .text-field-17:active {
    border: 1px solid #fbb919;
  }

  .text-field-17:focus {
    border: 1px solid #fbb919;
  }

  .text-field-17.input-half {
    width: calc(50% - 6px);
    margin-right: 0px;
    float: left;
    clear: none;
  }

  .text-field-17.input-half.half {
    float: right;
    clear: both;
  }

  .text-field-17.input-half.first-half {
    margin-right: 12px;
  }

  .text-field-17.input-fourth {
    width: calc(30% - 6px);
    float: left;
    margin-right:12px;
  }

  .text-field-17.input-three-fourth {
    width: calc(70% - 6px);
    float: right;
    clear: none;
  }
  .submit-button-2 {
    position: static;
    display: inline-block;
    overflow: visible;
    width: 100%;
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    float: left;
    clear: right;
    cursor:pointer;
    border-radius: 8px;
    background-color: #fbb919;
    /*font-family: 'Fontsfree net proxima nova';*/
    font-size: 28px;
    opacity:0.95;
    font-weight: 600;
  }

  .add_nav a:hover, .add_nav .current-menu-item a {
      color: #fbc011;
      transition: all 0.3s ease-out 0s;
      text-transform: uppercase;
  }

  .add_nav {
      list-style: outside none none;
      margin: 0 0 30px;
      padding: 0;
      text-transform: uppercase;
  }

  .add_nav li {
      display: inline-block;
      padding: 0 18px 0 19px;
      vertical-align: top;
      padding: 10px;
  }

  .registerSize.text-white {
    color: #fff
    }

  @media only screen and (min-width: 992px) {
      .marginBottom {
          margin-bottom: 32px;
      }
      .dateInfoMobile {
      margin-top:-65px !important;
      }
      .registerSize {
      border-radius: 8px;
      }
      .mobile-call-to-action {
        display:none;
      }
  }
  .marginBottom {
      margin-bottom: -55px;
  }

  .calculator_holder {
      /* color: #fff; */
      margin: 0 auto 16%;
      max-width: 1170px;
      overflow: hidden;
  }


  .started {
      padding: 5px;
  }
  .started1 {
      padding: 50px 15%;
      margin: 0px 0px 0px 0px;
  }
  .started2 {
      margin-top: 150px;
      padding:0px;
  }
  .startedH3 {
      text-align: left;
      margin: 35px;
      text-transform: uppercase;    
      }
  .startedH4 {
      text-align: left;
      margin: 20px 0px 0px 35px;
      text-transform: none;
      font: 16px/1.3 'Fontsfree net proxima nova', sans-serif;
      font-weight: 500;
      opacity:0.8;
      display: inline-block;

  }
  .startedP {
      text-align: left;
      font: 16px/2 'Fontsfree net proxima nova', sans-serif;
      margin: 10px 35px 0px 35px;
      font-weight: 200 !important;
      line-height: 1.3;
      opacity: 0.6;
  }

  .i-style{
    margin-left: 40px;
  }

  .startedN {
      text-align: left;
      margin: 35px;
      margin-left: 55px;
      font: 16px/2 'Fontsfree net proxima nova', sans-serif;
  }

  @media only screen and (max-width: 480px){
      .upload_mobile3 {
          margin: 10px 0px 0px 0px;
          padding: 0px !important;
      }
      .started_mobile {
          margin:0px !important;
          padding: 0px !important;
      }
      .started2 {
      margin-top: 80px;
      padding:0px;
      }
      .startedN {
      text-align: left;
      margin: 35px;
      margin-left: 45px;
      font: 16px/2 'Fontsfree net proxima nova', sans-serif;
      }
      .add_nav {
        padding: 35px;
      }
      .div-block {
      margin: 25px 0px 25px 0px;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1350px) {
  .center_holder, .calculator_holder, .content_holder, .thee_columns {
      margin: 0 auto 80%;
      max-width: none;
      padding: 0 20px;
  }

  .info_full_upload {
      background: #171c2d;
      color: #fff;
      display: block;
      font: 22px/1.2 'Fontsfree net proxima nova', sans-serif;
      padding: 50px;
      text-align: center;
      float: left;
      width: 100%;
      min-height: 400px;
  }
  }

  @media only screen and (max-width: 992px) {    
      .info_full_upload {
      background: rgba(55, 65, 88, 0.95);
      color: #fff;
      display: block;
      font: 22px/1.2 'Fontsfree net proxima nova', sans-serif;
      padding: 35px 12px 13px;
      text-align: center;
      /*border-radius: 8px;*/
      float: left;
      width: 100%;
      margin: 10px 10px 60px 10px;
      min-height: 400px;
      }
  }

  .started1 {
      padding: 50px 15%;
      margin: 0px 0px 0px 0px;
  }

  .add_nav a {
      color: #fff;
      transition: all 0.3s ease-out 0s;
      text-transform: uppercase;
  }

  a {
      color: #fbc011;
      outline: medium none;
      text-decoration: none;
  }

  .registerSize {
      width: 100% !important;
      background-color: rgba(55, 65, 88, 0.95);
  }

  .submit-button-2:disabled {
      background-color: #cccccc;
      border-color: transparent;
      opacity: 0.8 !important;
  }
}

.animate-transform {
  transition: .75s all;
}

#bird {
  animation: MoveUpDown 2s infinite;
}

@keyframes MoveUpDown{
  0%, 100% {
    margin-bottom: 0;
    // transform: 'translateX(0%) translateY(0px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)'
  }
  50% {
    margin-bottom: 9px;
    // transform: 'translateX(0%) translateY(0px) translateZ(-9px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)'
  }
}