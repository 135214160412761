.oleh-card {
  .oleh-card-header {
    border-radius: 6px 6px 0px 0px;
    background: $ligher_dark_blue;
    padding: 12px;
    h4 {
      margin: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 900;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.04em;
      color: $oleh_gray;
      b {
        color: $oleh_white;
      }
    }
  }
  .oleh-card-body {
    border-radius: 0px 0px 6px 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: $oleh_gray;
    padding: 12px;
    background-color: $ligher_dark_blue_2;
    p{
      margin: 0;
    }
    .footer-link {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      line-height: 10px;
      display: flex;
      align-items: center;
      letter-spacing: 0.24em;
      margin-top: 20px;
      color: $oleh_white;
      cursor:pointer;
      img{
        margin-left: 7px;
      }
    }
    .footer-link:hover {
      opacity: 0.8;
    }
  }
}